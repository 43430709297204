import React, { useEffect, useState } from 'react';
import './Skills.css';
import Python from '../components/images/python_logo.webp';
import Csharp from '../components/images/csharp_logo.webp';
import ReactLogo from '../components/images/react_logo.webp';
import Git from '../components/images/git_logo.webp';
import SQL from '../components/images/sql_logo.webp';
import CSS from '../components/images/css_logo.webp';
import HTML from '../components/images/html_logo.webp';
import AWS from '../components/images/aws_logo.webp';
import GCP from '../components/images/gcp_logo.webp';
import JS from '../components/images/js_logo.webp';
import Linux from '../components/images/linux_logo.webp';
import Node from '../components/images/nodejs_logo.webp';

const Skills = () => {
    const [animateSkills, setAnimateSkills] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const skillsSection = document.getElementById('skills');
            const sectionTop = skillsSection.offsetTop - window.innerHeight + 100;

            if (window.scrollY > sectionTop) {
                setAnimateSkills(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

return (
    <section id="skills" className={`skills-section ${animateSkills ? 'animate-skills' : ''}`}>
      <div className="container">
        <h2 className="skills-heading">&#60;skills/&#62;</h2>
        <div className="skills-list">
          <ul className="skills-grid">
            <li>
              <div className="skill">
                <img src={Python}/>
                <span>Python</span>
              </div>
            </li>

            <li>
              <div className="skill">
              <img src={JS}/>
                <span>JavaScript</span>
              </div>
            </li>
            
            <li>
              <div className="skill">
              <img src={HTML}/>
                <span>HTML</span>
              </div>
            </li>
            
            <li>
              <div className="skill">
              <img src={CSS}/>
                <span>CSS</span>
              </div>
            </li>
            
            <li>
              <div className="skill">
              <img src={ReactLogo}/>
                <span>React</span>
              </div>
            </li>
            
            <li>
              <div className="skill">
              <img src={SQL}/>
                <span>SQL</span>
              </div>
            </li>
            
            <li>
              <div className="skill">
              <img src={Git}/>
                <span>Git</span>
              </div>
            </li>
            
            <li>
              <div className="skill">
              <img src={Node}/>
                <span>Node.js</span>
              </div>
            </li>
            
            <li>
              <div className="skill">
              <img src={AWS}/>
                <span>AWS</span>
              </div>
            </li>
            
            <li>
              <div className="skill">
              <img src={GCP}/>
                <span>GCP</span>
              </div>
            </li>
            
            <li>
              <div className="skill">
              <img src={Csharp}/>
                <span>C#</span>
              </div>
            </li>

            <li>
              <div className="skill">
              <img src={Linux}/>
                <span>Linux</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
  
}

export default Skills;
