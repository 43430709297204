import React from 'react';
import './Projects.css';
import AppliSense from '../components/images/applisense_logo.png';
import HuCore from '../components/images/hucore_logo.png';
import Simon from '../components/images/simonsaysgif.gif';
import Apriori from '../components/images/apriori.jpg';
import TradePro from '../components/images/tradepro.jpg';
import ComingSoon from '../components/images/comingsoon.jpg';


const Projects = () => {
  const projectList = [
    { id: 1, title: 'AppliSense', description: 'Currently working on a web app that helps job seekers manage their' + 
    ' job applications effortlessly. Built using Python, Django, React, JS, MySQL, and GCP', ProjectImage: AppliSense },
    { id: 2, title: 'HuCore', description: 'Built an adventure game using C++ and incorporated ' +
    'multiple characters, environments, and levels.' +
    ' Used design principles and unit testing to achieve perfection', ProjectImage:  HuCore},
    { id: 3, title: 'Simon Says', description: 'Developed a fully functional Simon Says game using Python, with ' +
    'increasing levels of difficulty and a dynamic scoring system. Used Python (pygame) for development', ProjectImage:  Simon},
    { id: 4, title: 'Apriori Algorithm', description: 'Implemented the Apriori ' +
    'algorithm from scratch using Python and Jupyter notebook. In addition, suggested and implemented improvements.'
    , ProjectImage:  Apriori},
    { id: 5, title: 'TradePro', description: 'Created a trading bot that would scan stock charts ' +
    'in real time and send email updates upon meeting set conditions. Reduced research and analysis time by half.', ProjectImage:  TradePro},
    { id: 6, title: 'WW', description: 'Currently developing another web platform using ' +
    'AWS, Python, JavaScript, React, and some other frameworks. More information coming soon.', ProjectImage:  ComingSoon},
  ];

  const handleHover = (id) => {
    const project = document.getElementById(`project-${id}`);
    project.classList.add('hover');
  };

  const handleMouseLeave = (id) => {
    const project = document.getElementById(`project-${id}`);
    project.classList.remove('hover');
  };

  return (
    <section id="projects" className="projects-section">
      <div className="container">
        <h2 className="projects-heading">&#60;projects/&#62;</h2>
        <div className="projects-list">
          {projectList.map((project) => (
            <div className="project-item" key={project.id}>
              <div className="project-content">
                <img src={project.ProjectImage} alt="Project" />
                <div className="project-description">
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                </div>
              </div>
              <h3 className="project-title">{project.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
