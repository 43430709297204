import React, { useState } from 'react';
import axios from 'axios';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://script.google.com/macros/s/AKfycbwG5a2M3WzjkUcrDPfeBt0lL-T3fz_wsigFC5vOzNTdyfFakFpy8brlhz6MXz5LJ0yp/exec', formData);
      console.log('Form submitted:', response.data);
      setFormStatus('success');
    } catch (error) {
      console.error('Form submission error:', error);
      setFormStatus('error');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <h2 className="contact-heading">&#60;get_in_touch/&#62;</h2>
        <p className="contact-subheading">
  I'm currently open to new opportunities. Feel free reach out to me via email at{' '}
  <a href="mailto:vp.182k@gmail.com" className="contact-link">
    vp.182k@gmail.com
  </a>{' '}
  or connect with me on{' '}
  <a
    href="https://www.linkedin.com/in/pratxp/"
    target="_blank"
    rel="noopener noreferrer"
    className="contact-link"
  >
    LinkedIn
  </a>
</p>
        {/* <div className="contact-form">
          {formStatus === 'success' && <p>Form submitted successfully!</p>}
          {formStatus === 'error' && <p>Form submission failed. Please try again.</p>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div> */}
      </div>
    </section>
  );
};

export default Contact;
