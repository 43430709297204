import React, { useEffect, useState } from 'react';
import './Navbar.css';
import GitHubIcon from './images/github_icon.png';
import LinkedInIcon from './images/linkedin_icon.png';

const Navbar = () => {
  const [loaded, setLoaded] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 100);

    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      let scrollY = window.scrollY;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop - 100;
        const sectionHeight = section.offsetHeight;

        if (scrollY >= sectionTop && scrollY < sectionTop + sectionHeight) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    const yOffset = -100;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="left-section">
          <a href="/" className="title">singhvv.dev</a>
          <div className="social-links">
            <a href="https://www.github.com/singhvv" target="_blank" rel="noopener noreferrer">
              <img src={GitHubIcon} alt="GitHub" />
            </a>
            <a href="https://www.linkedin.com/in/pratxp" target="_blank" rel="noopener noreferrer">
              <img src={LinkedInIcon} alt="LinkedIn" />
            </a>
          </div>
        </div>
        <ul className={`nav-links ${loaded ? 'visible' : ''}`}>
          <li><a onClick={() => scrollToSection('home')} className={activeSection === 'home' ? 'active' : ''}>Home</a></li>
          <li><a onClick={() => scrollToSection('about')} className={activeSection === 'about' ? 'active' : ''}>About</a></li>
          <li><a onClick={() => scrollToSection('skills')} className={activeSection === 'skills' ? 'active' : ''}>Skills</a></li>
          <li><a onClick={() => scrollToSection('projects')} className={activeSection === 'projects' ? 'active' : ''}>Projects</a></li>
          <li><a onClick={() => scrollToSection('contact')} className={activeSection === 'contact' ? 'active' : ''}>Contact</a></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
