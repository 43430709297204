import React, { useEffect, useRef } from 'react';
import './Home.css';
import PortfolioHead from '../components/images/portfolio_head.png';
import Python from '../components/images/python_logo.webp';
import Csharp from '../components/images/csharp_logo.webp';
import ReactLogo from '../components/images/react_logo.webp';
import Git from '../components/images/git_logo.webp';
import SQL from '../components/images/sql_logo.webp';
import CSS from '../components/images/css_logo.webp';
import HTML from '../components/images/html_logo.webp';
import AWS from '../components/images/aws_logo.webp';
import GCP from '../components/images/gcp_logo.webp';
import JS from '../components/images/js_logo.webp';
import Linux from '../components/images/linux_logo.webp';
import Node from '../components/images/nodejs_logo.webp';


const Home = () => {
  const logoRefs = useRef([]);

  useEffect(() => {
    let rotateYValue = 0;
    let rotating = true;

    const rotateLogos = () => {
      logoRefs.current.forEach((logo, index) => {
        if (rotateYValue >= 180) {
          logo.style.zIndex = '1';
        } else {
          logo.style.zIndex = '3';
        }
      });
    };

    const interval = setInterval(() => {
      rotateYValue = rotateYValue % 360;
      rotateLogos();
      if (rotating) {
        rotateYValue += 30;
        logoRefs.current.forEach((logo, index) => {
          logo.style.transform = `rotateY(${rotateYValue}deg) translateZ(150px)`;
        });
      }
    }, 2000);

    return () => {
      clearInterval(interval);
      rotating = false;
    };
  }, []);


  return (
    <section id="home" className="section">
      <div className="container">
        <div className="content">
          <div className="text">
            <h1 className="name">Hello, I'm <span>&#60;Varun Pratap Singh/&#62;</span></h1>
            <h2 className="headline">Full Stack Developer</h2>
            <p>
              I've always had a keen interest in Software Development and have
              been coding for more than 5 years. I love solving challenging problems and
              throughout this portfolio, you'll see various examples of the projects
              I've worked on or am currently developing.
              Hope you have a great day!
            </p>
          </div>
          <div className="image">
            {/* <img src="./components/portfolio_head.png"/> */}
            <img className="portfolio-head"src={PortfolioHead} alt="Portfolio Head" />
            {/* Add logos with their respective classes for animation */}
            <div className="logos">
              <img className="logo" src={Python} alt="Python" />
              <img className="logo" src={Csharp} alt="Csharp" />
              <img className="logo" src={Git} alt="Git" />
              <img className="logo" src={ReactLogo} alt="ReactLogo" />
              <img className="logo" src={SQL} alt="SQL" />
              <img className="logo" src={GCP} alt="GCP" />
              <img className="logo" src={AWS} alt="AWS" />
              <img className="logo" src={HTML} alt="HTML" />
              <img className="logo" src={CSS} alt="CSS" />
              <img className="logo" src={Linux} alt="Linux" />
              <img className="logo" src={JS} alt="JS" />
              <img className="logo" src={Node} alt="Node" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
