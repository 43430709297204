import React, { useState, useEffect } from 'react';
import './About.css';


const About = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const aboutSection = document.getElementById('about');
      const sectionPosition = aboutSection.getBoundingClientRect().top;

      if (sectionPosition < window.innerHeight / 1.5) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id="about" className={`about-section ${isVisible ? 'fade-in' : ''}`}>
      <div className="container">
        <div className="about-content">
          <div className="text-right">
            <h2 className="about-heading">&#60;about_me/&#62;</h2>
            <div className="about-text">
              <p>
                Welcome to my portfolio! I'm a passionate software developer and have
                been coding for more than 5 years now. I have a Bachelor's degree 
                in Computer Science from the University of Lethbridge and
                am currently looking for new opportunities. My tech stack includes
                React, TypeScript, JavaScript, Node.js, SQL, HTML, API integration,
                CSS, Git, AWS, Google Cloud Platform, and Linux. I'm also
                experienced with Python, C#, and C/C++.
              </p>
              <p>
                I love building things and am always looking for new projects to work
                on. I have experience with a variety of different technologies and am
                always looking to learn more. I'm currently working with Tech Connect,
                as well as a couple of
                different projects, including a job application management tool and a
                SaaS idea. Feel free to get in touch!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
